<template>
  <div class="forgotPass_container">
    <div class="forgotPass_main">
      <img
        :src="require('@/assets/images/login/logo.png')"
        class="forgotPass_banner"
      />
      <div class="forgotPass_input_main">
        <div class="forgetPass_block_title">修改密码</div>
        <div class="forgotPass_input_form">
          <div class="forgotPass_block_row">
            <div class="forgotPass_block_key">账号:</div>
            <div class="forgotPass_block_value">
              <el-input
                placeholder="请输入账号"
                v-model="data.phone"
                class="forgotPass_block_input"
                required="required"
                autocapitalize="off"
              >
              </el-input>
            </div>
          </div>
          <div class="forgotPass_block_row">
            <div class="forgotPass_block_key">新密码:</div>
            <div class="forgotPass_block_value">
              <el-input
                placeholder="请输入4位新密码"
                v-model="data.passwd"
                @input="changePassword"
                class="forgotPass_block_input"
                required="required"
                autocapitalize="off"
              >
              </el-input>
            </div>
          </div>
          <div class="forgotPass_block_row">
            <div class="forgotPass_block_key">验证码:</div>
            <div class="forgotPass_block_value">
              <el-input
                placeholder="请输入验证码"
                v-model="data.code"
                class="forgotPass_block_input"
                autocomplete="new-password"
                required="required"
                autocapitalize="off"
              >
                <template class="forgotPass_block_input" #suffix>
                  <span @click="sendCode">{{
                    timer.text || "获取验证码"
                  }}</span></template
                >
              </el-input>
            </div>
          </div>
          <div class="forgotPass_block_row buton_grount">
            <CustomButton size="small" class="close_button" @click="doClose">
              取消修改</CustomButton
            >
            <CustomButton
              size="small"
              class="forgotPass_button"
              @click="doReset"
            >
              确认修改</CustomButton
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomButton from "@/components/unit/CustomButton";
import { Iphone, Lock } from "@element-plus/icons";
export default {
  name: "forgotPass",
  components: {
    CustomButton,
    Iphone,
    Lock,
  },
  data() {
    return {
      data: {
        phone: "",
        passwd: "",
        code: "",
      },
      codeLoading: false,
      loading: false,
      timer: {
        time: null,
        maxCount: 60000,
        startTime: 0,
        text: "",
      },
    };
  },
  created() {},
  methods: {
    sendCode() {
      window.scrollTo(0, 0);
      this.data.code = "";
      if (this.codeLoading || this.timer.time) return;
      if (!this.data.phone) {
        this.$tips.error({ text: "请输入手机号码" });
        return false;
      }
      if (!/^1\d{10}$/g.test(this.data.phone)) {
        this.$tips.error({ text: "手机号码不正确" });
        return false;
      }
      this.codeLoading = true;
      this.$axios
        .get(`${this.$urls.user.passwdSms}?phone=${this.data.phone}`)
        .then(() => {
          setTimeout(() => {
            this.codeLoading = false;
          }, 1000);
          this.timer.startTime = Date.now();
          this.checkTime();
          this.$tips.success({ text: "发送成功" });
        })
        .catch(() => {
          setTimeout(() => {
            this.codeLoading = false;
          }, 1000);
        });
    },
    changePassword() {
      this.data.passwd = this.data.passwd.replace(/\D/g, "").slice(0, 4);
    },
    checkTime() {
      if (this.timer.time) {
        clearTimeout(this.timer.time);
        this.timer.time = null;
      }
      let curTime = Date.now();
      if (this.timer.startTime + this.timer.maxCount > curTime) {
        this.timer.text = `${Math.floor(
          (this.timer.maxCount - (curTime - this.timer.startTime)) / 1000
        )}s`;
        this.$tools.setStorage("downStartTime", this.timer.startTime);
        this.timer.time = setTimeout(() => {
          this.checkTime();
        }, 1000);
      } else {
        this.timer.startTime = 0;
        (this.timer.text = ""), (this.codeLoading = false);
        this.$tools.setStorage("downStartTime", this.timer.startTime);
      }
    },
    verityData() {
      if (!this.data.phone) {
        this.$tips.error({ text: "请输入手机号码" });
        return false;
      }
      if (!/^1\d{10}$/g.test(this.data.phone)) {
        this.$tips.error({ text: "手机号码不正确" });
        return false;
      }
      if (!this.data.passwd) {
        this.$tips.error({ text: "请输入4位数字新密码" });
        return false;
      }
      if (!/^(\d{4})?$/.test(this.data.passwd)) {
        this.$tips.error({ text: "请输入4位数字新密码" });
        return false;
      }
      if (this.data.code == "") {
        this.$tips.error({ text: "请输入验证码" });
        return false;
      }
      return true;
    },
    doReset() {
      window.scrollTo(0, 0);
      if (!this.verityData() || this.loading) return;
      this.loading = true;
      this.$axios
        .patch(this.$urls.user.changePassword, this.data)
        .then((res) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          this.$tips.success({ text: "密码修改成功,请重新登录" });
          this.$router.push({
            path: "/login",
          });
        })
        .catch(() => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    doClose() {
      window.scrollTo(0, 0);
      this.$router.push({
        path: "/login",
      });
    },
  },
};
</script>
<style>
.forgotPass_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #fff8f8, #fde3f0);
  font-size: 0.8rem;
}
.forgotPass_main {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.forgotPass_banner {
  width: 10rem;
  object-fit: contain;
  margin-bottom: 0.5rem;
}

.forgotPass_input_main {
  width: 90%;
  margin: 1rem;
  padding: 1rem 0.5rem;
}
.forgotPass_input_form {
  width: 100%;
}
.forgotPass_block_row {
  flex: 1 0 0;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.forgotPass_block_key {
  width: 4rem;
  text-align: center;
}
.forgotPass_block_value {
  flex: 1 0 0;
}
.forgotPass_block_input > :nth-child(n) {
  border: 0;
}
.forgotPass_container input::placeholder {
  font-size: 0.8rem;
}
.forgotPass_container .el-input__suffix {
  font-size: 0.8rem;
}
.buton_grount {
  display: flex;
  justify-content: center;
}
.close_button {
  /* width: 10rem; */
  height: 1.6rem !important;
  margin: 0;
  margin-top: 1rem;
  background: #ccc;
  margin-right: 2rem;
}
.forgotPass_button {
  /* width: 10rem; */
  height: 1.6rem !important;
  margin: 0;
  margin-top: 1rem;
}
.forgotPass_block_row .el-input__suffix {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
}
.forgetPass_block_title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
  color: #e64638;
  margin-bottom: 2rem;
}
.forgotPass_container .el-input__suffix-inner {
  display: flex;
  align-items: center;
}
.forgotPass_container .el-input__inner {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.forgotPass_container .el-input__wrapper.is-focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
